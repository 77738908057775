
.custom_toast{
    color: #fff !important;
    display: flex !important;
    padding: 2px 2px !important;
    flex-grow: 1 !important;
    flex-wrap: wrap !important;
    font-size: 14px !important;
    align-items: center !important;
    font-weight: 400 !important;
    border-radius: 4px !important;
    color: #fff;
}
.custom_toast_success{
    background: #43a047 !important;
}
.custom_toast_error{
    background: #c20f0f !important;
}
.Toastify .Toastify__toast-container {
    width: auto;
}
.Toastify__toast{
    cursor: default;
}