.workspaceContainer{
    height: 100vh;
    min-height: 100vh;
}

.workspaceHeading {
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    align-self: flex-start;
}

.workspaceSubHeading {
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: 0em;
    align-self: flex-start;
}

.imgWorkspace{
    height: calc(100vh - 20px);
    width:100%
}

.workspaceBoxContainer{
    width: 400px;
    height: 300px;
    border-radius: 12px;
    position: relative;
    left: 100px;
}