.mainContainer{
    height: 100vh;
    min-height: 100vh;
}

.mainBoxContainer{
    width: 400px;
    height: 300px;
    border-radius: 12px;
    position: relative;
    left: 100px;
}

.kognitveLogo{
    position: absolute;
    width: 200px;
    top: 20px;
    left: 20px;
}